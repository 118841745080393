import React from 'react';
import { Link } from "gatsby";

const SubHeader = ({ slug }) => {
   console.log("aqi el sluf", slug)
    return (
        <div className="QuickLink" id="quick-link">

            <div className="arrown open"></div>
            <ul className="QuickLink_ul" style={{ marginRight: "207.75px" }}>
                <li><Link
                    style={{ color: "white " }}
                    activeClassName="active"
                    aria-label="Navegar al Detalle"
                    to={`/productos/${slug}`}> <span>Detalle</span></Link></li> 
                  <li><Link
                    style={{ color: "white " }}
                    activeClassName="active"
                    aria-label="Navegar a Especificaciones"
                    to={`/especifi/${slug}`}><span>Especificaciones</span></Link> </li>
            </ul>
        </div>
    );
};

export default SubHeader;