import React from 'react';
import Layout from '../layout'
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import Seo from '../seo';
import Header from '../../components/Header/newheader';
import { Link } from 'gatsby';
import SubHeader from '../SubHeader';
import Footer from '../Footer';


export const query = graphql`
query($slug: String ){
    allDatoCmsProducto(filter:{slug:{eq:$slug}}){
      nodes{
          slug
        titulo
        subtitulo
        contenido
        imagen{
          fluid(maxWidth:1200){
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
`;
const Productos = ({ data: { allDatoCmsProducto: { nodes } } }) => {

    const { titulo,subtitulo, contenido, imagen,slug } = nodes[0];
    return (
        <Layout>
            <Header/>
      
            <Seo title="Madtronic" description="Venta" />

         
            <section>
                <div className="product-quickLink">
                <SubHeader slug={slug}/>
                    <div className="PathRoute">
                        <Link to="/" className="click">Home  </Link>
                        <a className="list-item-none">{titulo}</a>
                        <a className="list-item-none">   Detalle</a>
                    </div>
                </div>
                <div className="p-detail">
                    <div className="p-detail-inner margin-top-44">
                        <div className="sp4-params-left1">
                        </div>
                        <div className="detail-img">
                            <div className="detail-img-big shown">
                                <Image fluid={imagen.fluid}  />
                            </div>

                        </div>
                        <div className="p-detail-right">
                            <p className="p-title">{titulo}</p>
                            <h3>{subtitulo}</h3>
                            <p className="p-left-param">{contenido}</p>
                            <Link to="" className="ant-btn ant-btn-primary">
                                <span className="pur_btn_icon"></span> <span>Comprar Ya!</span>
                            </Link>
                        </div>

                    </div>
                </div>



            </section>
            <Footer/>
        </Layout>
    );
};

export default Productos;