import React from 'react';
import RedesSociales from '../RedesSociales';

const Footer = () => {
    
    return (
      
     <footer className="footerFito"  >
             <RedesSociales/>
       <div style={{alignItems:"center",color:"#FFFFFF"}}>
    
      
        © 2021 by BestOffers PERU
       
      </div>
     
    
   </footer>
    );
};

export default Footer;