import React from 'react';
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
const RedesSociales = () => {
   
        const { image,image1} = useStaticQuery(graphql`
          query myqueryRedesSoc {
            image: file(relativePath: { eq: "Facebook.png" }) {
              sharp: childImageSharp {
                fluid(maxWidth: 100, maxHeight: 100) {
                ...GatsbyImageSharpFluid
               
                }
              }
            }
            image1: file(relativePath: { eq: "Instagram.png" }) {
              sharp: childImageSharp {
                fluid(maxWidth: 100, maxHeight: 100) {
                ...GatsbyImageSharpFluid
               
                }
              }
            }
          }
        `)
      
        const facebookImage = [image.sharp.fluid]
        const instragramImage = [image1.sharp.fluid]
    return (
        <div className="redesSociales">
             <a style={{marginRight:"1rem"}}
            href="https://www.facebook.com/BESTOFFERSPERU"
            target="_blank"
            rel="noreferrer"
          >
            <Img loading="lazy" style={{width: "3rem", height: "3rem", backgroundColor: "white", borderRadius:"25px" }} fluid={facebookImage} alt="facebook" />
          </a>
          <a
            href="https://www.instagram.com/BESTOFFERSPERU/"
            target="_blank"
            rel="noreferrer"
          >
            <Img loading="lazy" style={{ width: "3rem", height: "3rem", background: "white", borderRadius:"25px" }} fluid={instragramImage} alt="instragam" />
          </a>
        </div>
    );
};

export default RedesSociales;